import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

interface TitleAnimationProps {
  messages: {
    first_word: string;
    second_word?: string;
    third_word?: string;
  };
  lastWords?: string;
  isOneLine?: boolean;
  isSmall?: boolean;
  children?: React.ReactNode;
}

export const TitleAnimation = ({
  messages,
  lastWords,
  isOneLine = false,
  isSmall = false,
  children,
}: TitleAnimationProps) => {
  const containerRef = useRef(null);
  const firstWordRef = useRef(null);
  const secondWordRef = useRef(null);
  const uniqueWordRef = useRef(null);

  useEffect(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top bottom",
        end: isSmall ? "center 65%" : "center 40%",
        scrub: 2,
      },
    });

    timeline.fromTo(
      firstWordRef.current,
      { x: "-250px", backgroundPositionX: "100%" },
      { x: "0", backgroundPositionX: "0%" }
    );
    if (secondWordRef.current) {
      timeline.fromTo(
        secondWordRef.current,
        {
          x: isOneLine ? "-250px" : isSmall ? "130px" : "250px",
          backgroundPositionX: "100%",
        },
        { x: "0", backgroundPositionX: "0%" },
        "<"
      );
    }
    if (uniqueWordRef.current) {
      timeline.fromTo(uniqueWordRef.current, { x: "250px" }, { x: "0" }, "<");
    }

    return () => {
      timeline?.kill();
    };
  }, [isOneLine, isSmall]);

  return (
    <h2 className="title-section" ref={containerRef}>
      <span className="first-line-title" ref={firstWordRef}>
        {messages.first_word}
      </span>
      {messages.third_word ? (
        <p className={lastWords}>
          <span className="second-line-title" ref={secondWordRef}>
            {messages.second_word}
          </span>
          <span className="span-special title-outline" ref={uniqueWordRef}>
            {messages.third_word}
          </span>
        </p>
      ) : messages.second_word ? (
        <p className="span-special title-outline" ref={secondWordRef}>
          {messages.second_word}
        </p>
      ) : null}
      {children}
    </h2>
  );
};
