import React from "react";

export const TuxdiArrow = ({
  className,
  color,
  dataHover = null,
}: {
  className: string;
  color?: string;
  dataHover?: string;
}) => {
  return (
    <>
      <svg
        width="376"
        height="443"
        viewBox="0 0 376 443"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className} tuxdiArrow`}
        data-hover={dataHover}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.942 443H0L135.025 221.481L0 0H240.942L376 221.481L240.942 443Z"
          fill={color}
        />
      </svg>
    </>
  );
};
