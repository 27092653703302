import React from "react";
import style from "./ExpertiseCard.module.scss";
import LinkTransition from "../../../../transition/components/LinkTransition/LinkTransition";

interface ExpertiseCardProps {
  title: string;
  description: string;
  slug: string;
  tags: string[];
}

export const ExpertiseCard = ({
  title,
  description,
  slug,
  tags,
}: ExpertiseCardProps) => {
  return (
    <LinkTransition
      href={("/servicios/" + slug) as any}
      className={style.card}
      data-hover="expertise"
    >
      <div className={style.data} data-hover="expertise">
        <h3 data-hover="expertise">{title}</h3>
        <p data-hover="expertise">{description}</p>
      </div>
      <ul className={style.skills} data-hover="expertise">
        {tags.map((tag, index) => (
          <li key={index} data-hover="expertise">
            {tag}
          </li>
        ))}
      </ul>
    </LinkTransition>
  );
};
