import React, { useLayoutEffect } from "react";
import style from "./ExpertiseList.module.scss";
import { ExpertiseCard } from "../ExpertiseCard/ExpertiseCard";
import gsap from "gsap/all";

const ExpertiseList = ({ cardsServices, slug }: any) => {
  useLayoutEffect(() => {
    let tweens = [];
    let mm = gsap.matchMedia();

    mm.add("(min-width: 1200px) and (max-width: 1380px)", () => {
      const cards = document.querySelectorAll(`.${style.expertiseCard}`);

      cards.forEach((card, index) => {
        if (index === 0) {
          tweens.push(
            gsap.fromTo(
              card,

              {
                zIndex: slug ? 3 : null,
                y: -70,
                rotation: slug ? -5 : -20,
                left: slug ? 120 : 160,
              },
              {
                y: 0,
                rotation: 0,
                left: slug ? 0 : 330,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 20%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 1) {
          tweens.push(
            gsap.fromTo(
              card,
              {
                zIndex: slug ? 2 : 3,
                y: slug ? -90 : -140,
                rotation: slug ? 20 : -5,
                left: slug ? 30 : 90,
              },
              {
                y: 0,
                rotation: 0,
                left: slug ? 0 : 330,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 20%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 2) {
          tweens.push(
            gsap.fromTo(
              card,
              {
                zIndex: slug ? 1 : 2,
                y: -50,
                rotation: slug ? -14 : 20,
                right: slug ? 85 : 10,
              },
              {
                y: slug ? 0 : 500,
                rotation: 0,
                right: slug ? 0 : 330,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 20%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 3) {
          tweens.push(
            gsap.fromTo(
              card,
              { zIndex: 1, y: -90, rotation: 10, right: 120 },
              {
                y: 500,
                rotation: 0,
                right: 330,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 20%",
                  scrub: 1,
                },
              }
            )
          );
        }
      });
    });

    mm.add("(min-width: 1380px)", () => {
      const cards = document.querySelectorAll(`.${style.expertiseCard}`);

      cards.forEach((card, index) => {
        if (index === 0) {
          tweens.push(
            gsap.fromTo(
              card,
              {
                zIndex: slug ? 3 : null,
                y: -70,
                rotation: slug ? -5 : -20,
                left: slug ? 120 : 160,
              },
              {
                y: 0,
                rotation: 0,
                left: 0,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 34%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 1) {
          tweens.push(
            gsap.fromTo(
              card,
              {
                zIndex: slug ? 2 : 3,
                y: slug ? -90 : -140,
                rotation: slug ? 20 : -5,
                left: slug ? 30 : 90,
              },
              {
                y: 0,
                rotation: 0,
                left: 0,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 34%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 2) {
          tweens.push(
            gsap.fromTo(
              card,
              {
                zIndex: slug ? 1 : 2,
                y: -50,
                rotation: slug ? -14 : 20,
                right: slug ? 85 : 10,
              },
              {
                y: 0,
                rotation: 0,
                right: 0,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 34%",
                  scrub: 1,
                },
              }
            )
          );
        } else if (index === 3) {
          tweens.push(
            gsap.fromTo(
              card,
              { zIndex: 1, y: -90, rotation: 10, right: 120 },
              {
                y: 0,
                rotation: 0,
                right: 0,
                scrollTrigger: {
                  trigger: card,
                  start: "center 80%",
                  end: "top 34%",
                  scrub: 1,
                },
              }
            )
          );
        }
      });
    });

    return () => {
      tweens.forEach((tween) => {
        tween.scrollTrigger?.kill();
        tween.kill();
      });

      mm.revert();
    };
  }, []);

  return (
    <ul className={style.cards}>
      {cardsServices &&
        cardsServices.map((expertise: any, index: any) => (
          <li key={index} className={`${style.expertiseCard}`}>
            <ExpertiseCard
              slug={expertise.slug}
              title={expertise.title}
              description={expertise.description}
              tags={expertise.tags}
            />
          </li>
        ))}
    </ul>
  );
};

export default ExpertiseList;
